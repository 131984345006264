import React, { useEffect, useState } from 'react';
import { Route, Switch, Redirect, withRouter, NavLink } from 'react-router-dom';
import Header from '../components/global/header';
import BookingSheet from '../components/teeTime/bookingSheet';
import MyBooking from '../components/teeTime/myBooking';
import { getAuthState } from '../assets/scripts/login-util';

import swal from "sweetalert2";
import { io } from "socket.io-client";
import { getAddedPlayersByUserID, postActivityTracker } from '../components/api/teeTimeAPI';

const Master = (props) => {
    const path = window.location.hostname.includes('localhost') ? 'http://localhost:8080' : `${window.location.protocol}//cgc-admin.golfez.in`;
    const { user } = getAuthState();        

    const [socketMaster,    setSocketMaster]         = useState();
    const [teetimeUsers,    setTeetimeUsers]         = useState([]);
    const [refresh,         setRefresh]              = useState(false);
 
    useEffect(() => {
        if(user?.id == 4837 || user?.id == 4515 || user?.id == 1554){
            swal.fire({
                icon: 'warning',
                titleText: "Suspicious activity detected from this account.",
                position: 'center',
                showConfirmButton: false,
                showCloseButton: true,
                animation: false,
                allowOutsideClick: false,
                onAfterClose: () => { 
                    addActivity();
                },
            });
            const handleVisibilityChange = () => {
                if (document.visibilityState === 'visible') {
                    swal.fire({
                        icon: 'warning',
                        titleText: "Suspicious activity detected from this account.",
                        position: 'center',
                        showConfirmButton: false,
                        showCloseButton: true,
                        animation: false,
                        allowOutsideClick: false,
                        onAfterClose: () => { 
                            addActivity();
                        },
                    });
                }  
            };
            document.addEventListener('visibilitychange', handleVisibilityChange);
    
            // Cleanup the event listener on component unmount
            return () => {
                document.removeEventListener('visibilitychange', handleVisibilityChange);
            };
        }
    }, []);

    const addActivity = async() => {
        try {
            postActivityTracker();
        } catch (err) {
        }
    }
 
    useEffect(() => {                     
        if(!!props?.auth){
            initSocketConnection();        
            fetchActiveMembers();
        } 
    },[props.auth]);
    
    useEffect(() => {
        fetchActiveMembers();
    
    },[refresh])

    const fetchActiveMembers = async() => {
        try {
            const { TeeTimeMembers } = await getAddedPlayersByUserID();
            setTeetimeUsers(TeeTimeMembers);                           
            
        } catch (err) {
            swal.fire({ icon: "error", titleText: "Error!", text: err.message });
            return;  
        }
    }

    const refetchActiveMembers = ()=> setRefresh((r) => !r);

    //Connect's to intended socket io server -->
    const initSocketConnection = ()=> {
        try {    
            const token  = getAuthState('token');                
            const socket = io(path, { transports: ['websocket'], withCredentials:true } );		
            // const socket = io(path, { transports: ['polling','websocket'], upgrade: true , withCredentials: true});		
                
            //Emit user connected event to server -->
            socket.on('connect', ()=> socket.emit('user-connected', { socketID: socket.id, token }));

            setSocketMaster(socket);           
        } catch (err) {                    
            swal.fire({ icon: 'error', buttonsStyling: false, confirmButtonClass: 'btn btn-brand',
                text: 'Please Reload...', title: "Something went wrong..!",
            }); 
        }
    }

    return (  
        <>
            <Header/>
            <div className="container padding-t15">
                {/* Tab  */}
                <div id="pnlMyBooking" className="row margin-b5">
                    <div className="col-xs-6 padding-lr5">
                        <NavLink to="/tee-booking"  id="btnBook" activeClassName="btn-success" className="btn btn-default btn-block padding-lr10">Book Tee Time</NavLink>
                     
                    </div>
                    <div className="col-xs-6 padding-lr5">                      
                        <NavLink  to="/my-booking" id="btnMy" activeClassName="btn-success"  className="btn btn-default btn-block padding-lr10">My Bookings</NavLink>
                    </div>
                </div>

                <Switch>
                    <Route path="/tee-booking">
                        <BookingSheet socketMaster = {socketMaster} teetimeUsers = {teetimeUsers} refetchActiveMembers = {()=> refetchActiveMembers()}/>
                    </Route>
                    <Route path="/my-booking">
                        <MyBooking teetimeUsers = {teetimeUsers} refetchActiveMembers = {()=> refetchActiveMembers()}/>
                    </Route>

                    <Redirect to="/tee-booking" />
                </Switch>
                
            </div>
            <span className="container-power">
                <a className="power" href="https://golfezapp.com/" target="_blank">
                    <img src="https://golfezdev.s3.ap-south-1.amazonaws.com/Public/CGC/Icon/GolfEZ-powered_PNG.png" /> 
                </a>
            </span>
        </>
    );
};

export default withRouter(Master);