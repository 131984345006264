import React, { useEffect, useState } from "react";
import { NavLink } from 'react-router-dom';
import Swal from "sweetalert2";
import bannerLogo from '../../assets/images/CGC_Login_logo.png';
import { getAuthState, setAuthState } from "../../assets/scripts/login-util";
import { logout } from "../api/loginAPI";
import favImg from '../../assets/images/ic_group.png'
import AddMember from "../teeTime/addMember";


const Header = (props) => {

    const [username, setUsername]   = useState('User');
    const [showModal, setShowModal] = useState(false);

    useEffect(() =>{
        const cData = getAuthState()
        const { user } = cData; 

        if(!!user) setUsername(user.userName)
    },[])
  
    const logoutHandler = async () => {
		try {
			await logout()
			setAuthState();
            window.open('/', '_self');
		} catch (err) {
			window.open('/', '_self');
			Swal.fire({
				icon              : "error",
				titleText         : "Error!",
				text              :  err.message,
				buttonsStyling    : false,
				confirmButtonClass: "btn btn-brand",
			});
		}
	};

    return(
        <>
        {/*  Navigation */}
            <nav className="navbar navbar-default" role="navigation">
                <div className="container">     
                    <div className="row">
                        <div className="col-xs-10 padding-lr0"> 
                            <div className="navbar-brand">
                                <NavLink  to="/">
                                    <img id="asideBrandLogo" alt="Logo" src={bannerLogo} height="35"/>
                                </NavLink>
                                <div className="header_main">
                                    The Chandigarh Golf Club
                                    <small> Tee Time Booking</small>
                                </div>
                            </div> 
                        </div>
                    </div>
                </div>         
            </nav>
            {/* User */}
            <div className="container-volunteer">
                <div className="container">
                    <div className="row">
                        <div className="col-xs-12">
                            <span id="lblProfile" style={{lineHeight: "2.1" }} 
                            >Welcome <b>{username}</b></span>
                            <button id="btnLogout" onClick={logoutHandler} className="btn btn-link btn-sm pull-right"> Logout </button>
                        </div>
                    </div>
                </div>
            </div>

            {showModal && (
            <AddMember
                show            = {showModal}
                from            = {'header'}                                
                onDismissModal  = {() => setShowModal(false) }
            />
        )}

        </>
    )
}   

export default Header;