import React, { useEffect, useState } from 'react';
import Loader from '../global/loader';
import swal from 'sweetalert2';
import {getTeeTimeInit, getMyTeeTimeBooking, getTeeTimeBookUsers } from "../api/teeTimeAPI";
import BookingModal from './bookingModal';
import { getAuthState } from '../../assets/scripts/login-util';

const MyBooking = (props) => {

    const [loading,        setLoading]       = useState(false);    
    const [pageData,       setPageData]      = useState();
    const [bookingList,    setBookingList]   = useState([]);
    const [BookingUsers,   setBookingUsers]  = useState([]);

    const [teeBook,        setTeeBook] = useState();

    const [editValidation, setEditValidation] = useState();
    const { user } = getAuthState();        

    useEffect(() => {

        fetchMyBooking();
    }, [])
       
    const fetchMyBooking = async()=> {
        try {
            setLoading(true);

            const { TeeTimeValidation, ServerDate } = await getTeeTimeInit();               
            setPageData({TeeTimeValidation, ServerDate}); 
            
            const response = await getMyTeeTimeBooking();            
            const bookings = [...response.TeeTimeBook, ...response.ShotgunBook, ...response.OfflineTeeTimeBook, ...response.OfflineShotgunBook];
            const formatedList = bookings.sort((a,b) => (new Date(b.BookDate) - new Date(a.BookDate)));

            setBookingList(formatedList);            

            setLoading(false);
        } catch (err) {
            setLoading(false);
            swal.fire({ icon: "error", titleText: "Error!", text: err.message })
            return;
        }
    }
    
    const onSelectBook = async(book) => {                    
        try {   
            const user = getAuthState('user');        
            const type = book?.BookingType ? 'offline':'online';
            const response = await getTeeTimeBookUsers(book.ID, type); 
            
            setBookingUsers(response.User); 

            if(book?.BookingType != 'offline' ){
                //Set Edit & Cancel Flags -->                 
                const isOwner = (user?.id == book.UserID);
               
                setEditValidation({
                    IsEdit  : response?.IsEdit,                
                    IsCancel: (isOwner && response?.IsCancel)    
                });
            }else{
                setEditValidation({ IsEdit: false, IsCancel: false});
            }
            
            setTeeBook(book);
        } catch (err) {           
            swal.fire({ icon: "error", titleText: "Error!", text: err.message })
            return;
        }
    }

    return(<>
        {/* Listing */}

        <div className="row margin-t20">
            <div className="col-xs-12">
                {loading? <Loader/> : (<>
                    <div className="panel panel-default panel-club">
                        <table id="tblMyTeeTime" className="table table-striped">                      
                            <tbody>
                                {( bookingList.length > 0) ? (
                                    bookingList.map((b,i)=> (
                                        <tr key={b.ID}  onClick={()=> onSelectBook(b)}>
                                            <td className="td-block">
                                                <span ><b> {moment(b.BookDate).format('DD-MMM-YYYY')}, {b.Type == 'Shotgun' ? b.StartTime : b.Slot} </b> </span> <br/>
                                                <span >{b.Type == 'Shotgun' ? 'Shotgun Start,' + (' Hole ' + b.HoleNumber) : 'Staggered Start, ' + (b.TeeBox == 10 ? '10th Tee' : '1st Tee')} </span>
                                            </td>                                    
                                            
                                            <td className="td-block padding-r20">
                                                <div className='d-flex-end'>
                                                                                                  
                                                    {/* {(!!pageData) && (b.TemplateType == 'Slot') && (
                                                        moment(moment(b.BookDate).add(pageData.TeeTimeValidation.defaultBookingHours, 'h').add(pageData.TeeTimeValidation.defaultBookingMinutes, 'm')).isAfter(
                                                        moment(pageData.ServerDate).add(pageData.TeeTimeValidation.editHours, 'h').add(pageData.TeeTimeValidation.editMinutes, 'm'))) 
                                                        ?(<span className="text-right"><i className="fa fa-pencil fa-lg margin-lr10" aria-hidden="true"></i></span>) : <span></span>                                                
                                                    }   */}

                                                    {(!!pageData) && (b.BookingType != 'offline') &&  (
                                                        moment(moment(b.BookDate).add(pageData.TeeTimeValidation.defaultBookingHours, 'h').add(pageData.TeeTimeValidation.defaultBookingMinutes, 'm')).isAfter(
                                                        moment(pageData.ServerDate).add(pageData.TeeTimeValidation.editHours, 'h').add(pageData.TeeTimeValidation.editMinutes, 'm'))) && (b.UserID == user?.id)
                                                        ? ( <span className="color-blue margin-r15"><b> Edit </b></span>) : <span></span>                                                
                                                    }  
                                                                                                                                                    
                                                    <span><b> {b?.BookingType ? b.BookingType : 'Online'} </b></span>                                                
                                                </div>
                                            </td>
                                        </tr>
                                    ))
                                ):(
                                    <tr>
                                        <td>
                                            <div className="row wow fadeInUp animated">
                                                <div className="col-xs-12">
                                                    <h4 className="text-center text-uppercase text-muted margin-tb50">No Bookings Yet</h4>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                )                                
                               }
                            </tbody>
                        </table>
                    </div>
                </>) }
            </div>
        </div>


        {(!!teeBook) && (
            <BookingModal
                show                    = {!!teeBook }
                action                  = {'e'}
                onDismissModal          = {() => setTeeBook(null) }
                teeTimeBookData         = {teeBook}
                SelectedDate            = {teeBook.BookDate}
                bookingMembers          = {BookingUsers}
                editValidation          = {editValidation}
                refetchTeeTime          = {() => fetchMyBooking()}
                teetimeUsers            = {props.teetimeUsers}
                refetchActiveMembers    = {()=> props.refetchActiveMembers()}
            />
        )}

    </>)
}

export default MyBooking;