import React, { useState, useEffect } from 'react';
import bannerLogo from '../../assets/images/CGC_Login_logo.png';
import poweredBy from '../../assets/images/GolfEZ-powered_PNG.png';
import { NavLink } from 'react-router-dom';
import * as helper from '../global/helper';
import swal from 'sweetalert2';
import { sendRegistrationOTP, verifyRegistrationOTP } from '../api/loginAPI';
import { setAuthState } from '../../assets/scripts/login-util';
import { checkVersion } from '../../assets/scripts/versionComparer';
import { deviceDetect } from 'react-device-detect';

const Otp = (props) => {

    const [labelText,         setLabelText]         = useState('');
    const [sessionId,         setSessionId]         = useState(0);
    const [userID,            setUserID]            = useState(0);
    const [mobile,            setMobile]            = useState('');
    const [code,              setCode]              = useState('');

    useEffect(() => {
        setSessionId($.session.get('Code'));
        setMobile($.session.get('Mobile'));
        setUserID($.session.get('UserID')); 
        validationAttachments();      
    }, []);

    useEffect(() => {
        setLabelText('We have sent an OTP to ' + mobile + ' Please enter it to continue.');
    }, [mobile]);

    const verifyHandler = async (e) => {
        e.preventDefault();

        $('#ControlHolder').data('bootstrapValidator').validate();
        if ($('#ControlHolder').data('bootstrapValidator').isValid()) {

            try {
                swal.fire({
                    titleText           : 'Loading',
                    text                : 'Please Wait...',
                    showConfirmButton   : false,
                    allowOutsideClick   : false,
                    onOpen: () => {
                        swal.showLoading();
                    }
                });
                const deviceData = deviceDetect();
                const device = await helper.getDeviceDetails(deviceData)
                const response = await verifyRegistrationOTP(userID, sessionId, code, '', device);

                swal.close();

                 setAuthState({
                    token           : response.Token,
                    user            : {id: response.User.ID, userName: response.User.FirstName + ' ' + response.User.LastName},
                    FE_VERSION      : response.FE_VERSION,
                    serverTimestamp : response.serverTimestamp,
                    expiresOn       : response.expiresOn
                 }, true);

                 window.cookieChangeHandler();
                 checkVersion(response.FE_VERSION);
                 props.onLogin();

            } catch (err) {
                swal.close();
                swal.fire({
                    icon              : "error",
                    titleText         : "Error!",
                    text              : err.message
                  })
                return; 
            }
            
        }
    };

    const validationAttachments = () => {
        $('#ControlHolder').bootstrapValidator({
            fields: {
                txtCode: {
                    validators: {
                        notEmpty: {
                            message: 'Please enter OTP'
                        },
                        numeric: {
                            message: 'Number is required'
                        }
                    }
                }
            }
        });
    }

    const resendHandler = async (e) => {
        e.preventDefault();

        try {
            swal.fire({
                titleText           : 'Loading',
                text                : 'Please Wait...',
                showConfirmButton   : false,
                allowOutsideClick   : false,
                onOpen: () => {
                    swal.showLoading();
                }
            });
            const response = await sendRegistrationOTP(mobile);
            $.session.set('Code', response.Session);
            setSessionId(response.Session);
            swal.close();
            swal.fire({
                icon: 'success',
                titleText: 'OTP send to ' + mobile,
                showConfirmButton: false,
                timer: 1500,
            });
        } catch (err) {
            swal.close();
            swal.fire({
                icon              : "error",
                titleText         : "Error!",
                text              : err.message
              })
            return; 
        }
    };

    return (
        <div className="container margin-t50">
            <div className="row">
                <div className="col-md-4 col-md-offset-4">
                    <div className="login-panel">
                        <NavLink to={'/login'}>
                            <img className="img-responsive img-login" src={bannerLogo} alt="" />
                        </NavLink>
                    </div>
                    <div className="panel panel-default">
                        <div className="panel-heading">
                            <h3 className="panel-title">Verify OTP</h3>
                        </div>
                        <div className="panel-body">
                            <div id="otpForm" role="form">
                                <div id="ControlHolder">
                                    <span id="lblMobile" className="lbl">{labelText}</span><br /><br />
                                    <div className="form-group">
                                        <input 
                                            onChange={({ target }) => setCode(target.value)}
                                            value={ code || '' }
                                            id="txtCode" 
                                            name="txtCode" 
                                            className="form-control" 
                                            type="text" 
                                            placeholder="Code" 
                                            autoFocus="autofocus" 
                                        />
                                    </div>
                                </div>
                                <div className="clearfix form-actions">
                                    <button 
                                        type="button" 
                                        id="btnVerify" 
                                        name="btnVerify" 
                                        onClick={verifyHandler}
                                        className="btn btn-lg btn-success btn-block">Verify
                                    </button><br />
                                    <a href='#' onClick={resendHandler} className="lbl text-center">Resend OTP</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <span className="container-power-login">                        
                        <a className="power" href='https://golfezapp.com/' target="_blank">
                             <img src={poweredBy} alt=""/>
                        </a>
                    </span>
                </div>
            </div>
        </div>
    )

}
export default Otp;